import React from "react"
import { navigate, Link  } from "gatsby"
import Footer from "../components/footer"
import ImgBg from "../components/imgBg"

import brand from "../img/logo-brand.png"
import style from "./index.module.css"
import {
  FaShoppingCart,
  FaPaperPlane,
  FaCode,
  FaList,
} from "react-icons/fa"
import ReactPixel from "react-facebook-pixel";

//ReactPixel.track('HomeAkisuaFeira');


class Home extends React.Component {
  constructor(props) {
    super(props)
    this.scroll = null
  }

  componentDidMount() {
    ReactPixel.init('772526869537500',{},{debug:false, autoConfig:false});
    ReactPixel.pageView();
  }
  render() {
    return (
      <div>
        <div id={"wrapper"} className={style.wrapper}>
          <div className={style.container}>
            <div
              style={{
                position: "relative",
                height: 600,
                backgroundColor: "#78863f",
              }}
            >
              <ImgBg></ImgBg>
              <div className={style.containerInfo}>
                <div className={style.subContainerInfo}>
                  <img
                    src={brand}
                    alt="aquisuafeira"
                    className={style.brand}
                  ></img>
                  <p>
                    Aqui é onde você <b>compra</b> sua feira de{" "}
                    <b>frutas e verduras</b> e recebe em <b>sua casa.</b>
                  </p>
                  <a className={style.saibaMaisButton} href={"#saibamais"}>
                    Saiba Mais
                  </a>
                </div>
              </div>
            </div>
            <div id={"saibamais"} className={style.containerContent}>
              <div className={style.contentSaibaMais}>
                <FaCode size={40} color={"#3e490f"} />

                <h4>1º Acesse Nosso App</h4>
                <p>
                  Acesse nosso aplicativo e veja todas as frutas e verduras disponíveis no catálogo.
                  Selecione e informe a quantidade que deseja pedir, depois adicione no carrinho.

                </p>
              </div>
              <div className={style.contentSaibaMais}>
                <FaShoppingCart size={40} color={"#3e490f"} />
                <h4>2º Confirme seu carrinho</h4>
                <p>
                  Apos adicionar todos os itens no carrinho verificando o total do seu pedido, 
                  você poderá confirmar e se identificar com seu whatsapp ou email, caso não tenha um cadastro ainda,
                  é só se cadastrar no botão, não tenho cadastro.
                </p>
              </div>
              <div className={style.contentSaibaMais}>
                <FaPaperPlane size={40} color={"#3e490f"} />
                <h4>3º Envie seu Pedido</h4>
                <p>
                  Depois de confirmar o seus dados, informe a forma de pagamento preferêncial e logo apos envie seu pedido.
                  Você poderá acompanhar o andamento do seu pedido, ná tela de pedido enviado.
                  Acesse aplicativo no botão abaixo.
                </p>
              </div>
            </div>
            <div className={style.containerButtonCad}>
              <h2>Para acessar o aplicativo, clique no botão abaixo.</h2>
              <a
                href={"https://app.akisuafeira.com.br/"}
                className={style.linkButton}
              >
                Acesso ao aplicativo
              </a>
            </div>
            <Footer></Footer>
          </div>
        </div>
      </div>
    )
  }
}

export default Home
