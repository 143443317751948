import React from "react"
import styled from "styled-components"
import bg from "../../img/bgakisuafeira.jpg"

const Img = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.1;
  overflow: hidden;
  margin: 0;
`
export default () => (
    <Img src={bg}></Img>
);